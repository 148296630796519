import React from "react";
import Icon from '../../assets/Group30.png'
import { Container, Card, Title, Description, CardNew } from './style'
import { Button, Grid } from "@mui/material";

export default function Cards() {
  return (
    <>
      
    <Container>
      <Card background="#0058A1">
        <Title>
          +100 MIL.
        </Title>
        <Description>
          Itens cadastrados
        </Description>
      </Card>
      <Card background="#004782">
        <Title>
          +100 entregas.
        </Title>
        <Description>
          Realizadas mensalmente
        </Description>
      </Card>
      <Card background="#002F56">
        <Title>
          +1000 clientes.
        </Title>
        <Description>
          Satisfeitos com nossos produtos
        </Description>
      </Card>
    </Container>
    <CardNew background="#002543">
       <Grid container justifyContent="space-around">
        <Grid item >
          <div style={{ marginTop: '10%', display: 'flex' }}>
            <div style={{ marginTop: '-15px', marginRight: 25 }}>
              <img src={Icon} />
            </div>
            <div>
              <h3>Entre em contato conosco.</h3>
              <h3 style={{ fontWeight: '200' }}>Simples fácil <strong>& seguro.</strong></h3>
            </div>
          </div>
        </Grid>
        <Grid item >
          <Button variant="contained" style={{ color: "002543", fontWeight: 'bold', marginTop: '30%' }}>Atendimento</Button>
        </Grid>
       </Grid>
      </CardNew>
    </>
  );
}
