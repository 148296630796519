import React, { useEffect, useState } from "react";
import Line from "../Line";
import { Container, ContainerNew, Text, TextModal, Title, TitleModal } from './style.js'
import { Button, Card, Grid } from "@mui/material";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import P1 from '../../assets/Group 54.png'
import P2 from '../../assets/catalogos-sabo.jpg'
import P3 from '../../assets/catalogos-bgl.jpg'

import P4 from '../../assets/catalogos-skf.jpg';
import P5 from '../../assets/catalogos-frm.jpg';
import P6 from '../../assets/catalogos-timken.jpg'
import P7 from '../../assets/Group 54 (1).png'
import P8 from '../../assets/Group 54 (2).png'
import P9 from '../../assets/Group 54 (3).png'
import P10 from '../../assets/Group 54 (4).png'
import P11 from '../../assets/Group 54 (5).png'
import P12 from '../../assets/Group 54 (6).png'
import P13 from '../../assets/Group 54 (7).png'
import P14 from '../../assets/Group 54 (8).png'
import P15 from '../../assets/Group 54 (9).png'

export default function CatalogList() {
  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: 'smooth'
    });
  }, [])
  const products = [
    {
      titulo: "Timken",
      img: P6,
      descricao: "Rolamentos, Mancais, Buchas."
    },
    {
      titulo: "INA/FAG",
      img: P15,
      descricao: "Rolamentos"
    },
    {
      titulo: "FMR",
      img: P5,
      descricao: "Rolamentos Mancais."
    },
    {
      titulo: "SKF",
      img: P4,
      descricao: "Rolamentos, Mancais, Buchas."
    },
    {
      titulo: "NSK",
      img: P14,
      descricao: "Rolamentos"
    },
    {
      titulo: "NTN/SNR",
      img: P10,
      descricao: "Rolamentos"
    },
    {
        titulo: "HGF",
        img: P1,
        descricao: "Rolamentos."
    },
    {
        titulo: "Sabó",
        img: P2,
        descricao: "Retentores."
    },
    {
        titulo: "BGL",
        img: P3,
        descricao: "Buchas."
    },
    
    
    
    {
      titulo: "FK Bearings",
      img: P7,
      descricao: "Rolamentos, Mancais, Buchas."
    },
    {
      titulo: "VULKAN",
      img: P8,
      descricao: "Rolamentos, Mancais, Buchas."
    },
    {
      titulo: "Mademil",
      img: P9,
      descricao: "Polias"
    },
    
    {
      titulo: "SAV",
      img: P11,
      descricao: "Retentores"
    },
    {
      titulo: "Continental",
      img: P12,
      descricao: "Rolamentos"
    },
    {
      titulo: "Gates",
      img: P13,
      descricao: "Rolamentos"
    },
    
    
]

const [productModal, setProductModal] = useState({
  titulo: "", 
  img: "",
  descricao: ""
})
const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ContainerNew>
      <Container>
      <Line text={"Marcas parceiras"} />

      <Title>
        Catálogo.
      </Title>
        <Grid container justifyContent="space-evenly" >
          {products.map(item => (
            <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
              <Card raised style={{ padding: 5 }} >
                <img src={item.img} style={{ marginTop: 25, }} alt="" />
                <h3 style={{ marginTop: 45, }}>{item.titulo}</h3>
                <Text dangerouslySetInnerHTML={{__html: item.descricao.substring(0, 200)}} />
                <Button fullWidth variant="contained" onClick={() => {
                  setProductModal(item)
                  setOpen(true)
                }} style={{ background: '#0069C0', color: '#fff', fontWeight: 'bold', textTransform: 'capitalize' }}>Ver mais </Button>
              </Card>
            </Grid>
           ))}
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle id="alert-dialog-title">
        <TitleModal>
            {productModal.titulo + '.' || ""}
          </TitleModal>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <img src={productModal.img} alt="" />
         <TextModal dangerouslySetInnerHTML={{__html: productModal.descricao || ""}} />
        </DialogContent>
        <DialogActions>
          <Button style={{ color: '#0069C0' }} onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      
    </ContainerNew>
  );
}
