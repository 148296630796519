import React, { useEffect, useState } from "react";
import About from "../../Components/About";
import Banner from "../../Components/Banner";
import Cards from "../../Components/Cards";
import Contact from "../../Components/Contact";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Services from "../../Components/Services";
import CarroselComponent from "../../Components/Carrosel";
import Quality from "../../Components/Quality";
import Catalog from "../../Components/Catalog";
import ProductsList from "../../Components/ProductsList";

export default function Products() {
  const [blackHeader, setBlackHeader] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [tamanho, setTamanho] = useState(1000);

  useEffect(() => {
    const scrollListener = () => {
      setScroll(window.scrollY)
      setTamanho(window.screen.width)
      if (window.scrollY > 10) {
        setBlackHeader(true);
      } else {
        setBlackHeader(false);
      }
    }

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  })
  return (
    <>
      <Header scroll={scroll} blackHeader={blackHeader} tamanho={tamanho} />
      {/* <Banner />
      <About />
      <Cards />
      <Catalog/>
      <Quality/>
      {/* <Services />
      <CarroselComponent /> 
      <Contact /> */}
      <ProductsList />
      <Footer />
    </>
  );
}
