import styled from 'styled-components'

export const Container = styled.div`
    width: 45%;
    border: 'solid 3px #fff'; 
    border-radius: 5px; 
    padding: 25px 15px;
    display: 'flex';
    @media (max-width: 600px) {
        width: 100%;
        
        img{
            display: none;
        }
    }
`

export const Title = styled.h2`
    text-align: center; 
    font-weight: 150; 
    margin-top: 10px; 
    @media (max-width: 600px) {
        margin-top: 0px; 
    }
`