import styled from 'styled-components'

export const Container = styled.div`
margin-bottom: 8%;

@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 60px 0;
    background: white;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  

  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    
  }
  
  .logos-slide 
`

export const List = styled.div`
    overflow: hidden;
    padding: 60px 0;
    background: white;
    white-space: nowrap;
    position: relative;

    &:before, &:after{
        position: absolute;
        top: 0;
        width: 250px;
        height: 100%;
        content: "";
        z-index: 2;
    } 
`

export const Items = styled.div`
    display: inline-block;
    animation: 35s slide infinite linear; 
    img {
        height: 50px;
        margin: 0 40px;
    }
`

export const Title = styled.h1`
    font-weight: 800;
    padding: 0% 0% 0% 8%;
    margin-bottom: 5%;
    margin-top: 3%;
    margin-bottom: 5%;
    span{
        color: #C10133;
    }
    @media (max-width: 600px) {
        font-size: 39px;
    }
`