import React from "react";
import Line from "../Line";
import Rolamento from '../../assets/Group40.png'
import { Container, ContainerNew, Text, Title, TitleModal } from './style.js'
import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton, Typography } from "@mui/material";

import Nobre from '../../assets/catalogo-nobre-mancais.jpg'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconSecurity from '../../assets/catalogo-nobre-mancais.jpg'
import IconAvaliation from '../../assets/catalogos-sabo.jpg'
import IconRadar from '../../assets/catalogos-bgl.jpg'

import ImageSKF from '../../assets/catalogos-skf.jpg';
import ImageFRM from '../../assets/catalogos-frm.jpg';
import ImageTim from '../../assets/catalogos-timken.jpg'
import { useNavigate } from "react-router-dom";

export default function Catalog() {
  const [open, setOpen] = React.useState(false);
  const navigate  = useNavigate();
  const handleClickOpen = () => {
    navigate('/catalog')
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container>
      <Grid item md={6}>
        <Container>
          <Line text={"Veja nosso catálogo"} />
          <Title>
            Catálogo.
          </Title>
          <Text>
          Quer conhecer mais sobre nosso catálogo? É fácil! Acesse nosso catálogo online e descubra uma ampla variedade de opções para todas as suas necessidades. 
          Com apenas alguns cliques, você terá acesso a uma seleção completa de produtos de qualidade. Não perca tempo, explore agora mesmo e encontre exatamente o que procura!
          </Text>
          
          <Button onClick={handleClickOpen} style={{ margin: '0% 0% 0% 8%', color: "#fff", background: '#0069C0', fontWeight: 'bold' }} variant="contained">Ver catálogo completo</Button>
        </Container>
      </Grid>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle id="alert-dialog-title">
        <TitleModal>
            Catálogo.
          </TitleModal>
        </DialogTitle>
        <DialogContent>
          <Grid container>

          
        <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
            <Card raised >
              <img src={ImageFRM} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>FRM</h3>
              <Text>
              Rolamentos Mancais.
              </Text>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
            <Card raised>
              <img src={ImageSKF} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>SKF</h3>
              <Text>
              Rolamentos, Mancais, Buchas.
              </Text>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
            <Card raised>
              <img src={ImageTim} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>Timken</h3>
              <Text>
              Rolamentos, Mancais, Buchas.
              </Text>
            </Card>
          </Grid>
          </Grid>
          <Grid container>

          
        <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
            <Card raised >
              <CardMedia src="" />
              <img src={IconSecurity} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>Nobre</h3>
              <Text>
              Mancais.
              </Text>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
            <Card raised>
              <img src={IconAvaliation} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>Sabó</h3>
              <Text>
             Retentores.
              </Text>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '2%', textAlign: 'center' }}>
            <Card raised>
              <img src={IconRadar} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>BGL</h3>
              <Text>
              Buchas.
              </Text>
            </Card>
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: '#0069C0' }} onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog> */}
      <Grid item md={6}>
      <Grid container>

    <Card raised style={{ padding: '2%', margin: '2%', marginTop: '5%' }} >
      <img src={ImageFRM} style={{ marginTop: 25, }} />
      <h3 style={{ marginTop: 45, }}>FRM</h3>
      <Text>
      Rolamentos Mancais.
      </Text>
    </Card>
  
    <Card raised style={{ padding: '2%', margin: '2%', marginTop: '5%' }}>
      <img src={ImageSKF} style={{ marginTop: 25, }} />
      <h3 style={{ marginTop: 45, }}>SKF</h3>
      <Text>
      Rolamentos, Mancais, Buchas.
      </Text>
    </Card>
 

</Grid>
</Grid>
    </Grid>
  );
}
