import React from "react";
import Options from "./options";
import Logo from "../../assets/logoo.png";
import {
  Container,
  LogoImage,
  OptionsContainer,
} from './styles'
import { useNavigate } from "react-router-dom";

export default function Header({ scroll }) {

  const navigate  = useNavigate();
  return (
    <Container>
      <LogoImage>
        <img onClick={() => {navigate('/');}} style={{ width: 153, height: 80, cursor: 'pointer' }} src={Logo} alt="logo" />
      </LogoImage>
      <OptionsContainer>
        <Options topScroll={1} border={scroll < 400 && window.location.pathname == '/'} >
          Home
        </Options>
        <Options topScroll={500} border={scroll > 400 && scroll < 1500 && window.location.pathname == '/'} >
          Sobre Nós
        </Options>
        <Options topScroll={9998} border={scroll > 1500 && scroll < 2300 && window.location.pathname == '/' || window.location.pathname == '/catalog'} >
          Catálogo
        </Options>
        <Options topScroll={9999} border={scroll > 2300 && scroll < 2750 && window.location.pathname == '/' || window.location.pathname == '/products'} >
          Produtos
        </Options>
        <Options topScroll={2951} border={scroll > 2750 && window.location.pathname == '/'} >
          Contato
        </Options>
      </OptionsContainer>
    </Container>
  );
}
