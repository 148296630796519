import React from "react";
import { Option } from './styles'
import { useNavigate } from 'react-router-dom';

export default function Options({ children, border, topScroll }) {
  const navigate  = useNavigate();
  const scrollScreen = (top) => {
    if(topScroll == 9999) {
      navigate('/products');
    } else if(topScroll == 9998) {
      navigate('/catalog');
    }else {

      navigate('/');
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    } 
    

  }
  return (
    <div>
      <Option
        onClick={() => scrollScreen(topScroll)}
        border={border}
      >
        {children}
      </Option>
    </div>
  );
}
