import React from "react";
import { Container, Title } from './style'

export default function Line({ text }) {
  return (
    <Container>
      <div>
        <hr />
      </div>
      <Title style={{ fontWeight: 400 }}>{text}</Title>
    </Container>
  );
}
