import React from "react";

import {
  Container,
  TextBanner,
  Buttons
} from './styles'
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Banner() {
  const navigate  = useNavigate();
  return (
    <Container>
      <TextBanner>
        <h1 className="title" style={{ fontWeight: 400 }}>
        <strong>
        Impulsione sua<br />
        empresa, venha<br />
         com a impacto.
        </strong>
        </h1>
      <Buttons>
        <Button variant="contained" style={{ color: "#0069C0", fontWeight: 'bold' }} onClick={() => {navigate('/products');}}>Nossos produtos</Button>
        <Button style={{ marginLeft: 5, fontWeight: 'bold' }} onClick={() => {
          window.scrollTo({
            top: 500,
            behavior: 'smooth'
          });
        }}>+ conheça-nos</Button>
      </Buttons>
      </TextBanner>
    </Container>
  );
}
