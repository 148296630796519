import styled from 'styled-components'

export const Container = styled.div`
`
export const ContainerNew = styled.div`
h3{
    color: #0069C0;
    font-weight: 500;
}

`
export const Title = styled.h1`
    font-weight: 800;
    padding: 0% 0% 0% 4%;
    margin-top: 3%;
    span{
        color: #C10133;
    }
`

export const Text = styled.p`
    padding: 0% 0% 0% 8%;
    width: 85%;
    margin-top: 2%;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
`