import React, { useEffect, useState } from "react";
import Line from "../Line";
import Rolamento from '../../assets/Group40.png'
import { Container, ContainerNew, Text, TextModal, Title, TitleModal } from './style.js'
import { Button, Card, Grid } from "@mui/material";

import IconSecurity from '../../assets/shield-tick.png'
import IconAvaliation from '../../assets/box.png'
import IconRadar from '../../assets/radar.png'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import P1 from '../../assets/p1.jpg';
import P2 from '../../assets/p2.jpg';
import P3 from '../../assets/p3.jpg';
import P4 from '../../assets/p4.jpg';
import P5 from '../../assets/p5.jpg';
import P6 from '../../assets/p6.jpg';
import P7 from '../../assets/p7.jpg';
import P8 from '../../assets/p8.jpg';
import P9 from '../../assets/p9.jpg';
import P10 from '../../assets/p10.jpg';
import P11 from '../../assets/p11.jpg';
import P12 from '../../assets/p12.jpg';
import P13 from '../../assets/p13.jpg';
import P14 from '../../assets/p14.jpg';
import P15 from '../../assets/p15.jpg';
import P16 from '../../assets/p16.jpg';
import P17 from '../../assets/p17.jpg';
import P18 from '../../assets/p18.jpg';
import P19 from '../../assets/p19.jpg';
import P20 from '../../assets/Group 54 (10).png';
import P21 from '../../assets/Group 54 (11).png';
import P22 from '../../assets/Group 54 (13).png';
import P23 from '../../assets/Group 55.png';
import P24 from '../../assets/Group 55 (1).png';
import P25 from '../../assets/Group 54 (14).png';
import P26 from '../../assets/Group 54 (15).png';
import P27 from '../../assets/Group 54 (16).png';
import P28 from '../../assets/Group 54 (17).png';
import P29 from '../../assets/Group 54 (18).png';
import P30 from '../../assets/Group 54 (19).png';
import P31 from '../../assets/Group 54 (20).png';
import P32 from '../../assets/Group 54 (21).png';
import P33 from '../../assets/Group 54 (22).png';
import P34 from '../../assets/Group 54 (23).png';
import P35 from '../../assets/Group 54 (24).png';
import P36 from '../../assets/Group 54 (25).png';
import P37 from '../../assets/Group 54 (26).png';
import P38 from '../../assets/Group 54 (27).png';
import P39 from '../../assets/Group 54 (28).png';
import P40 from '../../assets/Group 54 (29).png';
import P41 from '../../assets/Group 54 (30).png';
import P42 from '../../assets/Group 54 (31).png';
import P43 from '../../assets/Group 54 (32).png';
import P44 from '../../assets/Group 54 (33).png';
import P45 from '../../assets/Group 54 (34).png';
import P46 from '../../assets/Group 54 (35).png';

import P47 from '../../assets/54.png';
import P48 from '../../assets/55.png';
import P49 from '../../assets/Group 56.png';
import P50 from '../../assets/Group 67.png';
import P51 from '../../assets/Group 66.png';
import P52 from '../../assets/Group 65.png';
import P53 from '../../assets/Group 64.png';
import P54 from '../../assets/Group 63.png';
import P55 from '../../assets/Group 62.png';
import P56 from '../../assets/Group 61.png';
import P57 from '../../assets/Group 68.png';
import P58 from '../../assets/Group 69.png';
import P59 from '../../assets/Group 70.png';
import P60 from '../../assets/Group 71.png';
import P62 from '../../assets/Group 75.png';
import P63 from '../../assets/Group 76.png';
import P64 from '../../assets/Group 77.png';
import P65 from '../../assets/Group 81.png';
import P66 from '../../assets/Group 79.png';
import P67 from '../../assets/Group 72.png';
import P68 from '../../assets/Group 73.png';
import P69 from '../../assets/Group 74.png';
import P70 from '../../assets/Group 78.png';
import P71 from '../../assets/Group 80.png';
import P72 from '../../assets/Group 57.png';
import P73 from '../../assets/Group 58.png';
import P74 from '../../assets/Group 59.png';
import P75 from '../../assets/Group 60.png';

import P76 from '../../assets/polias/Group 81.png';
import P77 from '../../assets/polias/Group 82.png';
import P78 from '../../assets/polias/Group 83.png';
import P79 from '../../assets/polias/Group 84.png';
import P80 from '../../assets/polias/Group 85.png';
import P81 from '../../assets/polias/Group 86.png';
import P82 from '../../assets/polias/Group 87.png';
import P83 from '../../assets/polias/Group 88.png';
import P84 from '../../assets/polias/Group 89.png';
import P85 from '../../assets/polias/Group 90.png';
import P86 from '../../assets/polias/Group 91.png';
import P87 from '../../assets/polias/Group 92.png';
import P88 from '../../assets/polias/Group 93.png';
import P89 from '../../assets/polias/Group 94.png';


import Wpp from '../../assets/whatsapp.png';

export default function ProductsList() {
  const productsRolamento = [
    {
        titulo: "Rolamentos de Rolos Cônicos",
        img: P1,
        descricao: "Esses rolamentos possuem contato angular e são compostos por elementos separados (cone e capa), o que simplifica a montagem. Sua concepção os torna especialmente adaptados para suportar cargas combinadas. Podem suportar cargas radiais muito pesadas em uma direção e toleram pequenos desalinhamentos. <br /><br /> <br /><br /> Séries: 30200, 30300, 31300, 32000, 32200, 32200B, 32300, 32300B, 33000, 33100, 33200."
    },
    {
        titulo: "Rolamento de uma carreira de esferas",
        img: P2,
        descricao: "Primariamente projetados para suportar cargas radiais moderadas, esses rolamentos são amplamente utilizados e também podem suportar cargas axiais moderadas em todas as direções. São particularmente úteis em aplicações de alta velocidade para minimizar perdas de energia. <br /><br /> Séries: 600, 6000, 6200, 6300, 6400, 16000, 61800, 61900, 62200, 62300, 63000."
    },
    {
        titulo: "Rolamento com duas carreiras de esferas",
        img: P3,
        descricao: "Podem ser de contato radial ou angular e substituem rolamentos de uma única fileira para suportar cargas radiais maiores e cargas axiais em ambas as direções. Comuns em motores elétricos, aparelhos eletrodomésticos, entre outros. <br /><br /> Séries: 4200, 4300, 3200, 3300, 5200, 5300."
    },
    {
        titulo: "Rolamento com uma carreira de esfera com contato angular",
        img: P4,
        descricao: "Os anéis internos e externos desses rolamentos são desnivelados para combinar cargas radiais e axiais. Dependendo da série, o ângulo de contato entre as esferas e os anéis varia para aumentar a capacidade de carga axial. Aplicações incluem caixas de redutores e hastes de máquinas-ferramenta. <br /><br /> Séries: 7200, 7300."
    },
    {
        titulo: "Rolamentos axiais autocompensadores de rolos",
        img: P5,
        descricao: "Projetados para suportar cargas axiais muito altas e serem pouco sensíveis a impactos. Podem suportar cargas radiais moderadas, mas têm uma velocidade de rotação relativamente baixa. Exemplos de aplicação incluem eixos verticais pesados e turbo alternadores. <br /><br /> Séries: 29200, 29300, 29400."
    },
    {
        titulo: "Rolamento com 4 pontos de contato",
        img: P6,
        descricao: "Concebidos para suportar cargas combinadas e cargas axiais em ambas as direções. Têm uma velocidade de rotação mais limitada em comparação com os rolamentos de contato radial. Usados em aplicações que exigem rolamentos que suportem cargas mistas. <br /><br /> Séries: QJ 200, QJ 300."
    },
    {
        titulo: "Rolamento autocompensador de rolos",
        img: P7,
        descricao: "Esses rolamentos suportam cargas radiais muito grandes, cargas axiais moderadas e corrigem pequenos erros de alinhamento. Podem ser fabricados com gaiolas de aço, bronze ou tecnologia especial para mecanismos vibratórios. Exemplos de aplicação incluem peneiras e trituradores. <br /><br /> Séries: 21300, 22200, 22300, 23000, 23100, 23200, 24000, 24100."
    },
    {
        titulo: "Rolamento de rolos cilíndricos",
        img: P8,
        descricao: "Recomendados para aplicações com cargas radiais elevadas e altas velocidades de rotação. Podem suportar cargas axiais pequenas. Disponíveis em várias variantes para melhorar o desempenho das máquinas. <br /><br /> Séries: N – NU – NJ – NUP / 200, 300, 400, 2200, 2300."
    },
    {
        titulo: "Rolamento autocompensador de esferas",
        img: P9,
        descricao: "Equipados com duas fileiras de esferas e uma pista esférica no anel externo, esses rolamentos compensam desalinhamentos do eixo. Úteis em aplicações com altas velocidades de rotação, cargas radiais moderadas e cargas axiais fracas ou moderadas. <br /><br /> Séries: 1200, 1300, 2200, 2300, 11200, 11300."
    },
    {
        titulo: "Rolamento axiais de esfera",
        img: P10,
        descricao: "Utilizados para manter o eixo em um único sentido e suportar grandes cargas axiais. Compostos por elementos separados, facilitando a montagem e manutenção. Exemplos de aplicação incluem eixos verticais e bombas de platô. <br /><br /> Séries: 51100, 51200, 51300, 51400, 52200, 52300, 52400, 53200, 53300, 53400, 54200, 54300, 54400."
    },
    {
        titulo: "Rolamento rígido de esferas ZZ",
        img: P11,
        descricao: "Os rolamentos rígidos de esferas são amplamente populares e podem suportar cargas radiais e axiais em ambas as direções. Podem vir com vedação para simplificar a estrutura e não requerem lubrificação adicional, sendo pré-lubrificados."
    },
    {
        titulo: "Rolamento rígido de esferas 2RS",
        img: P12,
        descricao: "Os rolamentos rígidos de esferas são amplamente populares e podem suportar cargas radiais e axiais em ambas as direções. Podem vir com vedação para simplificar a estrutura e não requerem lubrificação adicional, sendo pré-lubrificados."
    }
]

const productsMancais = [
  {
      titulo: "Mancais linha SN",
      img: P13,
      descricao: "Os mancais Plummer Block SN são montados com rolamentos de furo cônico e buchas de fixação. Fabricados em ferro fundido cinzento, com vedação FS. Comuns em aplicações"
  },
  {
      titulo: "Mancais linha SNH",
      img: P14,
      descricao: "Mancal versátil, desenvolvido para trabalho com rolamento autocompensador de esfera séries 1200 e 2200 ou autocompensador de rolo séries 22200 e 23200, onde o bloqueio do mesmo é feito através de anéis de bloqueio FRB. Possuem passagem de padrão nas laterais sendo possível a troca do tipo de vedação sem a substituição da caixa. A lubrificação pode ser à graxa ou a óleo sendo que deverá ser acrescido o sufixo RB. Deve-se especificar a quantidade de furos na base com os sufixos MS1 ou MS2, quando construído em ferro fundido nodular com base maciça."
  },
  {
      titulo: "Mancais linha SNH com vedação TG",
      img: P15,
      descricao: "Mancal versátil, desenvolvido para trabalho com rolamento autocompensador de esfera séries 1200 e 2200 ou autocompensador de rolo séries 22200 e 23200, onde o bloqueio do mesmo é feito através de anéis de bloqueio FRB. Possuem passagem de padrão nas laterais sendo possível a troca do tipo de vedação sem a substituição da caixa, No Caso a Vedação TG seria Vedação em Borracha Nitrílica (NBR) de Lábio Duplo, A lubrificação pode ser à graxa ou a óleo sendo que deverá ser acrescido o sufixo RB. Deve-se especificar a quantidade de furos na base com os sufixos MS1 ou MS2, quando construído em ferro fundido nodular com base maciça."
  },
  {
    titulo: "Mancal monobloco com rolamento linha ucf 200",
    img: P16,
    descricao: `Material: Carcaça em ferro fundido cinzento.<br />
    Rolamento em aço para rolamento de esferas 100Cr6.<br />
    Vedação em borracha NBR. A unidade de mancal é composta por um rolamento de esferas vedado de fileira única com anel externo abaulado montado em uma carcaça. Devido à superfície externa abaulada do rolamento, podem ser compensados desalinhamentos do eixo. Os rolamentos são fabricados com uma tolerância positiva. Por isso, a utilização de eixos com tolerâncias h resulta em encaixes de transição ou deslizantes. A fixação do eixo é efetuada através de pinos roscados no anel interno.<br /><br />
    
    Em condições normais de utilização, os mancais são livres de manutenção, devido à sua lubrificação permanente. Caso a utilização em condições ambientais adversas torne necessária uma nova lubrificação, é possível aplicar lubrificante através da engraxadeira.<br /><br />
    
    Todas as medidas da carcaça são medidas nominais em que devem ser consideradas as tolerâncias de fundição usuais. Faixa de temperatura:-15 °C até +100 °C<br /><br />`
},
{
  titulo: "Mancal monobloco com rolamento linha ucp 200",
  img: P17,
  descricao: `
  Os mancais ucp são indicados para as mais diversas aplicações industriais, principalmente onde há cargas moderadas. O mancal é bastante compacto e serve para dar apoio e fixar rolamentos. Ele é comumente encontrado dentro dos maquinários mais robustos, já que promove segurança e alto desempenho. Existem vários tipos de mancais no mercado, e escolher o melhor promove vantagens excelentes para o consumidor. Os mancais ucp contam com rolamentos de esferas de anel interno estendido.<br />
  Mancais Linha UCFL <br />
  Material: Carcaça em ferro fundido cinzento.<br />
  Rolamento em aço para rolamento de esferas 100Cr6.<br />
  Vedação em borracha NBR. A unidade de mancal é composta por um rolamento de esferas vedado de fileira única com anel externo abaulado montado em uma carcaça.<br /><br />
  
  Devido à superfície externa abaulada do rolamento, podem ser compensados desalinhamentos do eixo. Os rolamentos são fabricados com uma tolerância positiva. Por isso, a utilização de eixos com tolerâncias h resulta em encaixes de transição ou deslizantes. A fixação do eixo é efetuada através de pinos roscados no anel interno.<br /><br />
  
  Em condições normais de utilização, os mancais são livres de manutenção, devido à sua lubrificação permanente. Caso a utilização em condições ambientais adversas torne necessária uma nova lubrificação, é possível aplicar lubrificante através da engraxadeira.<br /><br />
  
  Todas as medidas da carcaça são medidas nominais em que devem ser consideradas as tolerâncias de fundição usuais. Faixa de temperatura:-15 °C até +100 °C
  `
},
{
  titulo: "Kit Mancal rolamento e bucha",
  img: P19,
  descricao: `
  Conjuntos PLUMMER BLOCK Em busca de fornecer produtos, soluções e serviços com inovação, qualidade e confiabilidade, com formas construtivas padronizadas, especiais e de diversos tamanhos, as linhas de mancais Plummer Block (bipartidos) possibilitam uma série de combinações de tipos de vedações que podem atender aos requisitos de diferentes aplicações. Todas estas variações resultam em uma ampla gama de opções de caixas para rolamentos, caso seja necessário nossa equipe está à disposição para sanar dúvidas e auxiliar na seleção dos tipos adequados de caixas para rolamentos e seus opcionais para cada aplicação.
  `
}
]

const productsBuchas = [
  {
    titulo: "Buchas",
    img: P18,
    descricao: `
    BUCHA DE FIXAÇÃO | H, HE, HA, HS<br />
    Usada para fixar rolamento de furo cônico em eixo liso (tolerância máxima h10), sem encosto.<br />
    É composta por 3 partes: a bucha, uma porca e uma arruela de trava ou grampo MS. São fornecidas sempre com esse conjunto completo
    `
  }
]

const productsRetentores = [
  {
    titulo: "Retentores",
    img: P20,
    descricao: `A função principal dos retentores é atuar em conjunto com os rolamentos de forma a reter óleos, graxas e outros fluídos. Também tem a função de proteção contra as mais diversas impurezas como poeira e areia protegendo o equipamento de possíveis danificações por conta de contaminação.

    <br/><br/>Geralmente a retenção ocorre em tudo que possui um eixo rotativo em condição estática ou dinâmica.`
  }
]

const productsLuminaria = [
  {
    titulo: 'Luminaria pública SMD',
    img: P21,
    descricao: `A Luminária Pública LED 150W SMD para Poste é muito mais do que apenas uma fonte de luz. Ela representa o casamento perfeito entre tecnologia avançada e eficiência luminosa. A luz é é compatível com postes de diferentes tamanhos e formatos, possui cor branca fria com temperatura de cor em torno de 6000K e proporciona um ambiente claro e bem iluminado.<br/><br/>

    Projetada especialmente para espaços externos, como ruas, praças e estacionamentos, esta luminária oferece um desempenho impecável e alta durabilidade, com resistência à água e poeira. Ela contribui, assim, para ambientes mais seguros e bem iluminados, além de garantir economia no casto de energia.`
  },
  {
    titulo: 'Luminaria pública COB',
    img: P22,
    descricao: `As Luminárias Públicas de LED substituem as luminárias tradicionais, oferecendo uma alta performance luminosa, obtendo uma economia de energia de até 80%, tornando-as a opção mais eficiente do mercado, gerando segurança para áreas públicas.`
  },
  {
    titulo: 'Refletor modular IP66',
    img: P23,
    descricao: `O Refletor Modular LED 150W Bivolt é ideal para iluminação de Fachadas, áreas externas e iluminação de destaque. Sua iluminação dirigida enfatiza a percepção dos objetos, superfícies e outros cujo facho luminoso contempla, por isso essa peça é perfeita para criar destaque ou iluminar pontos de interesses no ambiente. Podendo ser utilizado para criar inúmeros efeitos e ambientações, se limita a sua imaginação. São indicados para condomínios, monumentos, jardins, fachadas e ambientes externos corporativos e residenciais.`
  },
  {
    titulo: 'Luminária indústrial high bay',
    img: P24,
    descricao: `É a melhor opção para galpões, indústrias e centros de distribuição. Permite a instalação em perfilado, teto e ambientes frigorificados devido ao versátil sistema de suportes disponíveis. Reduz gastos com energia elétrica em até 90%, quando integrada ao sistema de Automação & Controle.`
  },
  {
    titulo: 'Luminária indústrial UFO',
    img: P25,
    descricao: `A Luminária indústrial UFO conta com uma alta eficiência do chip led e avançado sistema óptico, que garante lentes translúcidas e simétricas, proporcionando maior luminosidade e melhor distribuição da luz.<br/><br/>
    Combinado a flexibilidade no design, a alta resistência mecânica e o eficiente sistema de circulação de ar, reforça que os produtos em led são a melhor alternativa em iluminação, independente da aplicação para seu ambiente industrial.`
  },
  {
    titulo: 'Luminária hermética blindada',
    img: P26,
    descricao: `Quando se trata de iluminação confiável e durável, nossa Luminária Hermética é a escolha definitiva. Criada para enfrentar os desafios dos ambientes mais exigentes, esta luminária combina robustez, eficiência e proteção superior para garantir que seus espaços estejam sempre bem iluminados e seguros.
    `
  },
  {
    titulo: 'Luminária atelada embutir e sobrepor',
    img: P27,
    descricao: `Luminária para 4 ou 8 lâmpadas Tubo LED, indicada para a iluminação de ambientes onde há necessidade de controle de ofuscamento, como agências bancárias e salas de estudo.`
  },
  {
    titulo: 'Lâmpada bulbo E27 com adaptador E40',
    img: P28,
    descricao: `Ela conta com uma base E27 e acompanha um adaptador para a base E40, permitindo uma fácil instalação em diferentes tipos de luminárias. A lâmpada ideal para uma iluminação potente, garantindo uma excelente visibilidade em seu ambiente de trabalho!`
  },
  {
    titulo: 'Luminaria pública com placa solar e sensor',
    img: P29,
    descricao: `A Luminária Pública de Poste Solar é a escolha perfeita para iluminar ruas, praças, estacionamentos e áreas públicas. Sua tecnologia solar permite funcionamento independente da rede elétrica, com satisfação de energia e confiante para a sustentabilidade ambiental.<br/><br/>

    Com o controle de luminosidade, é possível ajustar o nível de intensidade da luz de acordo com a necessidade, otimizando o consumo de energia. Além disso, sua construção robusta e resistente às intempéries garante durabilidade e desempenho de longo prazo.`
  },
  {
    titulo: 'Refletor led com placa solar',
    img: P30,
    descricao: `Ilumina todos os tipos de espaços com potência e grande luminosidade.<br/><br/>

    Design exclusivo<br/>
    Com seu design frontal plano, ilumina espaços proporcionando estilo e praticidade.<br/><br/>
    
    Economize energia<br/>
    A eficiência energética é um sistema que classifica o consumo elétrico dos produtos.
    Como "A" é a categoria mais eficiente, com esse refletor você pode reduzir seu consumo energico em até 50%.`
  },
  {
    titulo: 'Refletor led SMD IP66',
    img: P31,
    descricao: `Completo para instalação, o Refletor LED SMD tem índice de proteção IP66, ou seja, é resistente a água e livre de intempéries, tornando-o um produto de maior vida útil e 100% seguro para uso externo. Sua avançada tecnologia SMD confere a este produto Chip LED de última geração com fluxo luminoso de qualidade, despontando-o como o melhor custo benefício do mercado. Cabe ainda ressaltar a economia de mais de 80% na conta de luz por ser um produto LED.. Seu baixo custo faz deste produto a melhor escolha para quem buscar um refletor de excelente eficiência com uma ótima rentabilidade.`
  },
  {
    titulo: 'Refletor SMD rgb - com memória',
    img: P32,
    descricao: `O refletor RGB é ideal para iluminação decorativa em festas, fachadas, vitrines e decoração em geral, são 16 opções de cores e 4 estilos de iluminação. O refletor possui memória que ao ligar novamente ele permanece na última cor ou efeito de iluminação.`
  },
  {
    titulo: 'Plafon led embutir e sobrepor',
    img: P33,
    descricao: `Um plafon LED é uma excelente alternativa para quem busca versatilidade, bom custo-benefício e uma iluminação distribuída de maneira eficiente. Com uma boa gama de modelos disponíveis, o plafon pode ser utilizado em diferentes cômodos, como salas, banheiros e cozinhas.`
  },
  {
    titulo: 'Painel led embutir e sobrepor',
    img: P34,
    descricao: `Se você está em busca de uma iluminação contemporânea que une estilo, praticidade e eficiência energética, os painéis de LED são a escolha ideal. Oferecemos uma ampla variedade de opções em painéis de LED para transformar qualquer ambiente em um espaço luminoso e convidativo.`
  },
  {
    titulo: 'Spot led embutir direcionável',
    img: P35,
    descricao: `A combinação perfeita de iluminação e design. Esta luminária dará vida aos seus espaços para que você possa aproveitá-los ao máximo. Além disso, você pode personalizar seus cantinhos favoritos e dar-lhes detalhes diferenciados.`
  },
  {
    titulo: 'Lâmpada led bulbo E27',
    img: P36,
    descricao: `Cada local da casa possui uma necessidade de lâmpada diferente. Para a escolha ideal, devemos levar em conta a atividade que será desenvolvida e o estilo de decoração do local`
  },
  {
    titulo: 'Lâmpada led tubular T8',
    img: P37,
    descricao: `Possuir uma boa iluminação nos espaços em que estamos influencia a maneira como nos sentimos, relaxamos, trabalhamos ou estudamos. É por isso que a lâmpada tubular T8 pode ser uma excelente opção se você quiser criar um ambiente agradável e funcional.`
  },
  {
    titulo: 'Luminaria sobrepor slim',
    img: P38,
    descricao: `Luminária tubular LED de teto Slim para quarto, salas e sacadas da Elgin. Design discreto com eficiente atenuação proporcionando luz difusa e uniforme, esse produto foi desenvolvido com fluxo luminoso de 2400lm e potência de 36W. Além de ser bivolt, uma das suas maiores características é que ela é durável, com vida útil de 25000 horas e de sobrepor.`
  },
  {
    titulo: 'Fita led 2835 ou 5050',
    img: P39,
    descricao: `Este é um produto de qualidade cenográfica. As Fitas de led possuem alto nível de construção e componentes, com uma maior quantidade de LEDs por metro e tensão 24V, além de uma maior taxa de eficiência luminosa, que é mais brilhante e consome menos energia.`
  },
  {
    titulo: 'Arandela led tartaruga',
    img: P40,
    descricao: `A arandela tartaruga é um tipo de iluminação que une versatilidade, beleza e simplicidade aos projetos de iluminação, trazendo uma sensação de maior conforto e aconchego ao ambiente, seja ele interno ou externo.`
  },
  {
    titulo: 'Spot led para trilho eletrificado',
    img: P41,
    descricao: `A iluminação tem um papel fundamental tanto na funcionalidade quanto na decoração dos ambientes. Nesse sentido, o spot de trilho tem sido cada vez mais usado, já que uma única peça é capaz de iluminar vários pontos do cômodo, com um design moderno e arrojado.`
  },
  {
    titulo: 'Balizador embutido solo',
    img: P42,
    descricao: `As peças certas para iluminar o seu jardim de maneira clean e incrível. Basta escolher aquele que mais se adequa e alcança a altura da vegetação existente e deixar o seu lar ainda mais convidativo.`
  },
  {
    titulo: 'Espeto led para jardim',
    img: P43,
    descricao: `As peças certas para iluminar o seu jardim de maneira clean e incrível. Basta escolher aquele que mais se adequa e alcança a altura da vegetação existente e deixar o seu lar ainda mais convidativo.`
  },
  {
    titulo: 'Fonte eletronica e chaveada 12 e 24v',
    img: P44,
    descricao: `O Drive para luminária led foi especialmente desenvolvido para proteger o led.
    Caso ele não existisse a tensão prejudicaria o led e comprometeria seriamente o desempenho do produto. Passando pelo driver, a tensão é controlada e a lâmpada não
    corre nenhum risco. Portanto, o Drive 12W-24W atende unicamente a luminária dessa voltagem .`
  },
  {
    titulo: 'Perfil de led sobrepor 17mm x 7mm',
    img: P45,
    descricao: `Os Perfis de LED são versáteis permitindo uma iluminação difusa criando um ambiente moderno, sofisticado e criativo.
    É possível embutir ou sobrepor o material e ainda possuem tamanhos variados. São comumente utilizados em paredes e tetos de alvenaria, paredes e forros de gesso e também em moveis.`
  },
  {
    titulo: 'Perfil de led embutir 24mm x 7mm',
    img: P46,
    descricao: `Os Perfis de LED são versáteis permitindo uma iluminação difusa criando um ambiente moderno, sofisticado e criativo.
    É possível embutir ou sobrepor o material e ainda possuem tamanhos variados. São comumente utilizados em paredes e tetos de alvenaria, paredes e forros de gesso e também em moveis.`
  },
]

const productAcoplamento = [
  {
    titulo: 'Acoplamento AE_A',
    img: P47,
    descricao: 'A linha de acoplamentos AE_A Padrão é utilizada onde a distância entre os eixos dos equipamentos acoplados é relativamente pequena. Indústrias que necessitam ter em seu processo acoplamento com montagem rápida, fácil e precisa, buscando melhorar o desempenho da sua maquina e diminuir paradas para manutenção.'
  },
  {
    titulo: 'AES_A COM ESPAÇADOR',
    img: P48,
    descricao: `CONQUISTE A EXCELÊNCIA NA PERFOMANCE DE SEU EQUIPAMENTO <br/><br/>
    Os Acoplamentos ACRIFLEX® Linha AE_A Bi-Partidos são compostos de apenas quatro componentes, dois cubos simétricos em aço 1045 e um elemento bi-partido longitudinalmente de poliuretano.`
  },
  {
    titulo: 'AX CUBO NORMAL',
    img: P49,
    descricao: `
    Os cubos são produzidos em Aço Sae 1020 ou Ferro Fundido Nodular, o que proporciona maior resistência e confiabilidade ao material.<br/><br/>

    Absorve desalinhamentos reduzindo os esforços e aumentando a vida útil dos componentes.<br/><br/>
    
    Para as mesmas dimensões existem dois modelos, um cuja capacidade de torque é maior em função das características do elemento elástico de maior resistência.
    `
  },
  {
    titulo: 'AX CUBO INTEGRAL',
    img: P50,
    descricao: `O acoplamento AX cubo Integral é um produto desenvolvido pela Acriflex. Os cubos são produzidos em Aço Sae 1020 ou Ferro Fundido Nodular, o que proporciona maior resistência e confiabilidade ao material. o modelo AX integral permite a utilização em diâmetros maiores de eixo, em relação ao Acoplamento AX cubo normal.`
  },
  {
    titulo: 'AX COM ESPAÇADOR',
    img: P51,
    descricao: `Os cubos são produzidos em Ferro Fundido Nodular, o que proporciona maior resistência e confiabilidade ao material.<br />
    Absorve desalinhamentos reduzindo os esforços e aumentando a vida útil dos componentes. Proporcionam uma eficiente redução do nível de ruído a partir do isolamento de vibrações e choques entre motor e máquina. Garante uma perfeita isolação elétrica entre os eixos, evitando com isso, uma importante causa de colapso de rolamentos. Amortecimento de vibrações e choques.<br />
    Manutenção simples, não é necessário mover o motor para substituição da banda elástica. Dispensam lubrificação.`
  },
  {
    titulo: 'AX CUBO BIPARTIDO',
    img: P52,
    descricao: `Os cubos são produzidos em Ferro Fundido Nodular, o que proporciona maior resistência e confiabilidade ao material.<br />
    Absorve desalinhamentos reduzindo os esforços e aumentando a vida útil dos componentes. Proporcionam uma eficiente redução do nível de ruído a partir do isolamento de vibrações e choques entre motor e máquina. Garante uma perfeita isolação elétrica entre os eixos, evitando com isso, uma importante causa de colapso de rolamentos. Amortecimento de vibrações e choques.<br />
    Manutenção simples, não é necessário mover o motor para substituição da banda elástica. Dispensam lubrificação`
  },
  {
    titulo: 'ACOPLAMENTO AG',
    img: P53,
    descricao:  `Os acoplamentos AG são compostos por dois cubos simétricos de ferro fundido cinzento e um elemento elástico de borracha sintética de elevada resistência à poeira, óleo e água. O Acoplamento é torcionalmente elástico e flexível, logo essas características o fazem capaz de absorver vibrações, choques, desalinhamentos radiais, axiais e angulares. Utilizado tanto em posição horizontal e vertical, desde que corretamente fixados. Aceitam reversões de movimentos.<br /><br/>
    Temperaturas de trabalho na faixa de -20ºC a 80Cº. Por sua forma construtiva simples, a instalação é rápida e segura. A manutenção é minimizada, pois não há necessidade de lubrificação
    `
  },
  {
    titulo: 'ACOPLAMENTO AG_H',
    img: P54,

    descricao: `A forma construtiva do acoplamento AG-G permite o afastamento axial da capa para inspecionar ou trocar o elemento elástico sem o deslocamento dos cubos.<br /><br />
    A forma construtiva do acoplamento AG-H permite a remoção radial do espaçador para inspecionar ou trocar o elemento elástico sem o deslocamento dos cubos.
    `
  },
  {
    titulo: 'ACOPLAMENTO AG_G',
    img: P55,
    descricao: `A forma construtiva do acoplamento AG-G permite o afastamento axial da capa para inspecionar ou trocar o elemento elástico sem o deslocamento dos cubos.<br/><br/>
    A forma construtiva do acoplamento AG-H permite a remoção radial do espaçador para inspecionar ou trocar o elemento elástico sem o deslocamento dos cubos.
    `
  },
  {
    titulo: 'ACOPLAMENTO AF',
    img: P56,
    descricao: `O acoplamento de GRADE ELÁSTICA AF, consiste em dois cubos de aço SAE 1045 inteiramente usinados e protegidos por uma camada antioxidante, grade elástica em aço ligado tratado altamente resistente e elementos de blindagem: Tampas, Guarnição, Anéis de veração e Parafusos. Utilizado na posição horizontal e vertical, pois seu sistema de vedação gera grande resistência a entrada de substâncias abrasivas e evita a perda de lubrificante. Os acoplamentos de GRADE ELÁSTICA AF podem ser usados na maioria das aplicações industriais e foi desenvolvido para prover uma vida longa de serviço e proteger ao máximo o equipamento.`
  },
  {
    titulo: 'ACOPLAMENTO AT',
    img: P58,
    descricao: `O acoplamento de GRADE AT, consiste em dois cubos de aço SAE 1045 inteiramente usinados e protegidos por uma camada antioxidante, grade elástica em aço ligado tratado altamente resistente e elementos de blindagem: Tampas, Guarnição, Anéis de veração e Parafusos. As tampas bi-partidas horizontalmente, permitem que a manutenção seja realizada rapidamente sem a necessidade de mover equipamentos. As grades tem seção trapezoidal e todos os tamanhos são compostos por camadas simples, facilitando encaixe e desencaixe das mesmas nas ranhuras dos cubos. Absorve vibrações, choques e compensação de desalinhamentos paralelos, axiais e angulares. `
  },
  {
    titulo: 'ACOPLAMENTO AWR',
    img: P57,
    descricao: `Os acoplamentos ACRIFLEX AW R são compostos por dois cubos simétricos de aço carbono 1045, um elemento elástico
    alojado entre eles e uma tampa de aço. Suas garras são totalmente usinadas, proporcionando um perfeito alojamento com o elemento elástico, resultando numa vida útil maior do elemento. Possui elemento amortecedor de poliuretano com
    excelente resistência ao desgaste.
    Suporta temperatura de trabalho até 90° C.`
  },
  {
    titulo: 'ACOPLAMENTO ABN',
    img: P59,
    descricao: `Os acoplamento ACRIFLEX® da linha ABN são flexíveis e torsionalmente elásticos. Além de absorver choques e vibrações provenientes da máquina acionada ou acionadora, permite desalinhamentos axias, angulares e radias.
    Permite a substituiçãos dos elementos elásticos sem a necessidade do afastamento das máquinas acopladas.
    Os elementos elásticos são produzidos em poliuretano, podendo ser utilizado em ambiente químicos agressivos. Isento de lubrificação. Os acoplamentos da linha ABN são indicados para serviços pesados.`
  },
  {
    titulo: 'ACOPLAMENTO ABND',
    img: P60,
    descricao: `Os acoplamento ACRIFLEX® da linha ABN são flexíveis e torsionalmente elásticos. Além de absorver choques e vibrações provenientes da máquina acionada ou acionadora, permite desalinhamentos axias, angulares e radias.
    Permite a substituiçãos dos elementos elásticos sem a necessidade do afastamento das máquinas acopladas.
    Os elementos elásticos são produzidos em poliuretano, podendo ser utilizado em ambiente químicos agressivos. Isento de lubrificação. Os acoplamentos da linha ABN são indicados para serviços pesados.`
  },
  {
    titulo: 'ACOPLAMENTO ESPECIAL',
    img: P63,
    descricao: `Os acoplamentos ACRIFLEX® AGS são compostos por dois cubos em alumínio ou em aço, e um elemento elástico alojado entre eles, de poliuretano. Os cubos são totalmente usinados,
    proporcionando um perfeito alojamento com o elemento elástico. Acoplamento sem folga, utilizado em acionamentos de precisão como encoder, tacogerador e fuso de esfera. Montagem axial sem ferramentas e de fácil inspeção visual.
    Compacto com baixo efeito rotacional e alto torque devido à geometria das garras côncavas.`
  },
  {
    titulo: 'ACOPLAMENTO AGR',
    img: P62,
    descricao: `Os acoplamentos ACRIFLEX AGR são compostos por dois cubos em aço e um elemento elástico alojado entre eles de
    poliuretano. Montagem axial sem ferramentas e de fácil inspeção visual.
    Compacto com baixo efeito rotacional e alto torque devido à geometria das garras côncavas. Os cubos são totalmente usinados, proporcionando um perfeito alojamento com o elemento elástico e ótimas
    propriedades dinâmicas. Os acoplamentos ACRIFLEX AGR são compostos por dois
    Temperatura de trabalho até 90º C`
  },
  {
    titulo: 'ACOPLAMENTO AGS',
    img: P64,
    descricao: `Os acoplamentos ACRIFLEX® AGS são compostos por dois cubos em alumínio ou em aço, e um elemento elástico alojado entre eles, de poliuretano. Os cubos são totalmente usinados, proporcionando um perfeito alojamento com o elemento elástico. Acoplamento sem folga, utilizado em acionamentos de precisão como encoder, tacogerador e fuso de esfera. Montagem axial sem ferramentas e de fácil inspeção visual.
    Compacto com baixo efeito rotacional e alto torque devido à geometria das garras côncavas.
    Temperatura de trabalho até 90º C`
  },
  {
    titulo: 'ACOPLAMENTO AWA – AWAC',
    img: P65,
    descricao: `Os acoplamentos Acriflex AWA e AWAC são indicados para Encoder, servo motores e motores de passos. Conexão de eixos sem folgas para acionamentos de precisão com torque baixo.
    Tem dimensões reduzidas com baixo momento de inércia. Alta rigidez torcional em aplicações com rotação sem reversão e flexibilidade angular. Por sua forma construtiva dispensa manutenção e não necessita de lubrificação.
    Temperatura de trabalho até 93º C`
  },
  {
    titulo: 'ACOPLAMENTO AE',
    img: P66,
    descricao: `Os acoplamentos ACRIFLEX® AE são compostos de apenas 3 elementos. Duas engrenagens com dentes de perfil especial em ferro fundido e uma capa externa em superpoliâmidia (nylon), oferecendo o máximo de vantagens em sua utilização. Dispensa lubrificação e manutenção preventiva. Montagem simples e rápida. Baixo peso relativo à carga transmitida.
    Ideal para acionamentos industriais, em especial, para sistemas hidráulicos.
    Desalinhamento angular máximo
    +/- 2º e paralelo +/- 1MM
    Temperatura de trabalho até 90º C`
  },
  {
    titulo: 'ENGRENAGEM G',
    img: P69,
    descricao: `O acoplamento de engrenagem ACRIFLEX® linha G, consiste em dois cubos e duas tampas de aço SAE 1045 inteiramente usinados e protegidos por uma camada antioxidante, e elementos de blindagem: Guarnição, Anéis de vedação e Parafusos de alta resistência. Os dentes triplamente abaulados dos acoplamentos de engrenagem ACRIFLEX® são abaulados na raiz, ponta e face podendo articular-se livremente e minimizar o desgaste causado pelo desalinhamento. Sua fabricação está totalmente normalizada e padronizada dentro da Norma ANSI/AGMA 9008- B00 sendo assim intercambiável com os demais fabricantes que seguem esta norma. Ideal para aplicações com torque elevado. Os acoplamentos de engrenagem são utilizados em vários tipos de aplicações e acomoda desalinhamentos angulares, paralelos e combinados.`
  },
  {
    titulo: 'ACOPLAMENTO TN',
    img: P67,
    descricao: `Os acoplamentos TN são flexíveis e torcionalmente elástico. Tem grandes vantagens devido ao seu processo de fabricação, que permite trabalhar com desalinhamentos radiais, axiais e angulares. São compostos por dois cubos simétricos de ferro fundido nodular e um elemento elástico alojado entre eles. Elemento composto por malhas internas transmissão de torque e borracha vulcanizada na parte externa. Essas duas características agregadas em um único acoplamento, permitem que seu elemento elástico possa trabalhar em ambientes altamente agressivos, exposto a água, poeiras, resíduos de óleos e à temperaturas de -40ºC até 100ºC.`
  },
  {
    titulo: 'ACOPLAMENTO AD',
    img: P68,
    descricao: `O Acoplamento ACRIFLEX AD, consiste em dois flanges simétricos inteiramente usinados, pinos de aço com superfícies retificadas e buchas amortecedoras de borracha nitrílica à prova de óleo, fixadas por anéis elásticos. Absorve vibrações e choques, permite desalinhamento paralelo, angular e axial. Tem grande elasticidade torcional e não dá origem a forças axiais prejudiciais aos mancais.
    Apto para trabalhar em altas e baixas velocidades, em posição horizontal e vertical. Permite remover as máquinas sem deslocá-las longitudinalmente.
    Permite substituição das buchas amortecedoras sem desmontagem do próprio acoplamento. Não requer manutenção preventiva, nem lubrificação.
    Temperatura de trabalho até 80º C`
  },
  {
    titulo: 'ACOPLAMENTO ASN',
    img: P70,
    descricao: `Os acoplamentos ACRIFLEX® ASN são compostos por dois cubos simétricos de ferro fundido nodular, e um elemento elástico alojado entre eles, de poliuretano. Suas garras são totalmente usinadas, proporcionando um perfeito alojamento com o elemento elástico. Elemento amortecedor de poliuretano, com dureza de 95 shore, que absorve vibrações e choques, provenientes das máquinas acionadas ou acionadoras. Resistente a óleo e a temperatura de até 85°C. Dispensa lubrificação e manutenção, apenas substituição preventiva do elemento elástico. Adequada para trabalho reversível, em qualquer posição. Baixo peso relativo à carga transmitida.`
  },
  {
    titulo: 'ACOPLAMENTO AV',
    img: P73,
    descricao: `Os acoplamentos ACRIFLEX® AV são compostos por dois cubos simétricos de ferro fundido cinzento, e um elemento elástico alojado entre eles, de poliuretano resistente à poeira, água, oléo e imtempéries. Esta configuração o torna apto ao ser torcionalmente elástico e fléxivel em todas as direções absorvendo vibrações, choques, desalinhamentos radiais, axiais e angulares; protegendo desta forma os equipamentos acoplados. Estes acoplamentos permitem trabalho em posição horizontal e vertical, desde que corretamente fixados, e aceitam reversões de movimentos, Podem ser usados em temperaturas de –20°C a 85°C. Por sua construção simplificada, permite instalação rápida e segura, dispensam
    cuidados e ferramentas especiais para sua montagem. Em função de suas garras, este acoplamento é à prova de deslizamento rotativo. Não necessitam manutenção preventiva e nem lubrificação. São compactos, possuem baixo peso, e consequentemente um baixo momento de inércia.`
  },
  {
    titulo: 'ACOPLAMENTO AZ',
    img: P72,
    descricao: `Os acoplamentos ACRIFLEX® AZ são compostos por dois cubos simétricos de ferro fundido cinzento, e um elemento elástico alojado entre eles, de borracha sintética de elevada resistência a abrasão. Esta configuração torna apto ao acoplamento ser torcionalmente elástico e flexível em todas as direções, absorvendo vibrações, choque, desalinhamentos radial, axial e angular, protegendo desta forma os Equipamentos acoplados. Não necessitam manutenção preventiva e nem lubrificação. Apto para o trabalho reversível, em horizontal e vertical. São compactos, possuem baixo peso e baixo momento de inércia.
    AC
    Temperatura de trabalho até 80º C`
  },
  {
    titulo: 'ACOPLAMENTO AM',
    img: P74,
    descricao: `Os acoplamentos ACRIFLEX® AM são compostos por dois cubos simétricos de ferro fundido cinzento,
    e um elemento elástico alojado entre eles, de borracha sintética de elevada resistência a abrasão. Elemento amortecedor de borracha resistente a óleos minerais, que absorve vibrações e choques, trabalhando silenciosamente sem provocar forças radiais ou axiais prejudiciais aos mancais. Dispensa lubrificação e manutenção preventiva.
    Adequada para trabalho reversível, em qualquer posição. Baixo peso relativo à carga transmitida.
    
    Temperatura de trabalho até 80º C`
  },
  {
    titulo: 'ACOPLAMENTO AC',
    img: P71,
    descricao: `O acoplamento ACRIFLEX® AC é composto por dois cubos simétricos de ferro fundido cinzento, unidos por um jogo de
    correias planas fixadas aos cubos através de um flange aparafusado. Esta configuração torna apto ao acoplamento ser torcionalmente elástico e flexível em todas as direções, absorvendo vibrações, choques, desalinhamentos radiais, axiais e angulares; protegendo desta forma os equipamentos acoplados. Pelas suas características construtivas os acoplamentos são empregados em situações de trabalho desfavoráveis e que possuem grandes desalinhamentos.`
  },
  {
    titulo: 'ACOPLAMENTO LINHA AWF',
    img: P75,
    descricao: `Os acoplamentos ACRIFLEX® AWF são compostos por dois cubos de Aço Carbono, um espaçador em Aço Carbono, dois jogos de lâminas flexiveis em Aço Inoxidável e elementos de fixação. Alta rigidez torcional, mantendo o sicronismo dos equipamentos acoplados.
    Elementos flexiveis fabricadas em aço inoxidável série 300. Opera em altas temperaturas. Fácil substituição dos jogos de laminas, não e necessário mover o motor para substituição.
    Dispensa lubrificação. Inspenção visual sema necessidade de desmontar o equipamento.
    Atendem os requisitos da norma API 610.
    Temperatura de trabalho até 150° C`
  },
]

const productPolia = [
  {
    titulo: 'Polia de Ferro Fundido 3V',
    descricao: '',
    img: P82
  },
  {
    titulo: 'Polia de Ferro Fundido 5V',
    descricao: '',
    img: P83
  },
  {
    titulo: 'Polia Trapezoidal Perfil A - SPA',
    descricao: '',
    img: P81
  },
  {
    titulo: 'Polia Trapezoidal Perfil B - SPA',
    descricao: '',
    img: P76
  },
  {
    titulo: 'Polia Trapezoidal Perfil B - SPA',
    descricao: '',
    img: P77
  },
  {
    titulo: 'Polias Reguláveis',
    descricao: '',
    img: P78
  },
  {
    titulo: 'Polias de Alumínio Escalonada',
    descricao: '',
    img: P84
  },
  {
    titulo: 'Polias de Alumínio Perfil A-SPA',
    descricao: '',
    img: P80
  },
  {
    titulo: 'Polias de Alumínio Perfil B-SPB',
    descricao: '',
    img: P89
  },
  {
    titulo: 'Polias de Alumínio Perfil Z',
    descricao: '',
    img: P88
  },
  {
    titulo: 'Montagem e Desmontagem',
    descricao: '',
    img: P79
  },
  {
    titulo: 'Cone de Aperto (Bucha Cônica)',
    descricao: '',
    img: P86
  },
  {
    titulo: 'Polias de Ferro Perfil 3V com Cone do Tipo QD',
    descricao: '',
    img: P87
  },
  {
    titulo: 'Polias de Ferro Perfil C e SPC com Cone do Tipo Q',
    descricao: '',
    img: P85
  }
]


const [productModal, setProductModal] = useState({
  titulo: "", 
  img: "",
  descricao: ""
})
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = React.useState(productsRolamento);
  const [active, setActive] = React.useState(1);

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: 'smooth'
    });
  }, [])

  return (
    <ContainerNew>
      <Container>
      <Line text={"Conheça nossos produtos"} />

      <Title>
        Produtos.
      </Title>

        <Grid container justifyContent="space-around" textAlign="center" style={{ marginTop: 35 }}>
          <Grid item xs={12} md={1.7}>
            <Button onClick={() => {
              setActive(1)
              setProducts(productsRolamento)
            }} variant={active == 1 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 1 ? "#fff" : '#0069C0', background: active == 1 ? "#0069C0" : '#fff' }}>Rolamentos</Button>
          </Grid>
          <Grid item xs={12} md={1.7} >
            <Button onClick={() => {
              setActive(2)
              setProducts(productsMancais)
            }} variant={active == 2 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 2 ? "#fff" : '#0069C0', background: active == 2 ? "#0069C0" : '#fff' }}>Mancais</Button>
          </Grid>
          <Grid item xs={12} md={1.7}>
            <Button onClick={() => {
              setActive(3)
              setProducts(productsBuchas)
            }} variant={active == 3 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 3 ? "#fff" : '#0069C0', background: active == 3 ? "#0069C0" : '#fff' }}>Buchas</Button>
          </Grid>
          <Grid item xs={12} md={1.7}>
            <Button onClick={() => {
              setActive(4)
              setProducts(productsRetentores)
            }} variant={active == 4 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 4 ? "#fff" : '#0069C0', background: active == 4 ? "#0069C0" : '#fff' }}>Retentores</Button>
          </Grid>
          <Grid item xs={12} md={1.7}>
            <Button onClick={() => {
              setActive(5)
              setProducts(productsLuminaria)
            }} variant={active == 5 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 5 ? "#fff" : '#0069C0', background: active == 5 ? "#0069C0" : '#fff' }}>Iluminação</Button>
          </Grid>
          <Grid item xs={12} md={1.7}>
            <Button onClick={() => {
              setActive(6)
              setProducts(productAcoplamento)
            }} variant={active == 6 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 6 ? "#fff" : '#0069C0', background: active == 6 ? "#0069C0" : '#fff' }}>Acoplamento</Button>
          </Grid> 
          <Grid item xs={12} md={1.7}>
            <Button onClick={() => {
              setActive(7)
              setProducts(productPolia)
            }} variant={active == 7 ? "contained" : "outlined"} style={{ border: '1px solid #0069C0', color: active == 7 ? "#fff" : '#0069C0', background: active == 7 ? "#0069C0" : '#fff' }}>Polias</Button>
          </Grid> 
        </Grid>


        <Grid container justifyContent="space-evenly">
          {products.map(item => (
            <Grid item xs={12} md={3} style={{ padding: '2%', textAlign: 'center' }}>
              <Card raised style={{ padding: 5 }} >
                <img src={item.img} style={{ marginTop: 25, }} alt="" />
                <h3 style={{ marginTop: 45, }}>{item.titulo}</h3>
                <Text dangerouslySetInnerHTML={{__html: item.descricao.substring(0, 200)}} />
                <div style={{ display: 'flex', maxHeight: 45 }}>

                <Button variant="contained" onClick={() => {
                  setProductModal(item)
                  setOpen(true)
                }} style={{ background: '#0069C0', color: '#fff', fontWeight: 'bold', textTransform: 'capitalize', width: '80%', marginLeft: 0 }}>Ver mais </Button>
                <a href='https://wa.me/5511952682339'><Button variant="contained" style={{ background: '#25d366', color: '#fff', fontWeight: 'bold', textTransform: 'capitalize', width: '20%', marginLeft: 5 }}><img src={Wpp} alt="" /></Button></a>
                </div>
              </Card>
            </Grid>
           ))}
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle id="alert-dialog-title">
        <TitleModal>
            {productModal.titulo + '.' || ""}
          </TitleModal>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <img src={productModal.img} />
         <TextModal dangerouslySetInnerHTML={{__html: productModal.descricao || ""}} />
        </DialogContent>
        <DialogActions>
          <Button style={{ color: '#0069C0' }} onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      
    </ContainerNew>
  );
}
