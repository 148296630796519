import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

import "./styles.css";
import Products from "./pages/Products";
import Catalogs from "./pages/Catalog";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/products"  element={<Products />} />
          <Route path="/catalog"  element={<Catalogs />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
