import styled from "@emotion/styled";
import img from '../../assets/banner98.jpeg'
import img1 from '../../assets/Vector18.png'

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    background-image: url(${img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 80vh;
    @media (max-width: 600px) {
        margin-top: 35%; 
        img{
            display:none;
        }
    }
`

export const TextBanner = styled.div`
    padding: 15% 0% 0% 4%;
    width: 50%;
    background-image: url(${img1});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    h1{
        padding: 7% 0% 0% 8%;
        font-size: 48px;
        color: #DFE320; 
        text-align: left;
    }
    @media (max-width: 600px) {
        width:100%;
        padding: 25% 0% 0% 4%;
        h1{
            font-size: 25pt;
        } 

        button{
            width: 50%;
            font-size: 8pt;
            margin-top: 25px;
            margin-left: -15px;
        }
    }
    @media (min-width: 600px) {
        padding: 8% 0% 0% 4%;
    }
    @media (min-width: 1800px) {
        padding: 13% 0% 0% 4%;
    }
`

export const ImageBanner = styled.div`
    padding: 12% 0% 0% 8%;
    @media (max-width: 600px) {
        img{
            display:none;
        }
    }
`

export const Buttons = styled.div`
   display: flex;
   padding: 2% 0% 0% 10%;

`