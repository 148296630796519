import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import React from "react";

const ButtonEdit = styled(Button)(({ theme }) => ({
  padding: 10,
  paddingLeft: 50,
  paddingRight: 50,
  marginBottom: "15%",
}));

export default function Contact() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            color: '#fff',
            textAlign: "center",
            background: 'linear-gradient(0.25turn, #05467D, #0069C0, #05467D)',
            padding: 15,
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: 100, marginTop: "3%" }}>Vamos Conversar.</h1>
          <h1 style={{ fontWeight: 800 }}>
            Fale com um de nossos consultores!
          </h1>
          <p style={{ fontWeight: 100, marginTop: "3%", marginBottom: "5%" }}>
            Estamos aqui para te auxiliar, envie nos uma
            mensagem.
          </p>
          <Grid item>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={5}
            >
              <Grid item>
                <ButtonEdit
                  onClick={(e) => {
                    window.location.href = "https://wa.me/5511952682339";
                    e.preventDefault();
                  }}
                  variant="outlined"
                >
                  WhatsApp
                </ButtonEdit>
              </Grid>
              <Grid item>
                <ButtonEdit
                  onClick={(e) => {
                    window.location.href = "mailto:vendas@impactodistribuicao.com.br";
                    e.preventDefault();
                  }}
                  variant="outlined"
                >
                  Email
                </ButtonEdit>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
