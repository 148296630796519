import styled from 'styled-components'

export const Container = styled.div`
`
export const ContainerNew = styled.div`
@media (max-width: 600px) {
    img{
        display:none;
    }
}
`
export const Title = styled.h1`
    font-weight: 800;
    padding: 0% 0% 0% 8%;
    margin-top: 3%;
    span{
        color: #C10133;
    }
`

export const Text = styled.p`
    padding: 0% 0% 0% 8%;
    width: 85%;
    margin-top: 2%;
`