import styled from 'styled-components'

export const Container = styled.div`
    
`

export const ServicesCard = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
    padding: 0
    @media (max-width: 600px) {
        margin-left: 1%;
    }
`

export const Title = styled.h1`
    font-weight: 800;
    padding: 0% 0% 0% 8%;
    margin-top: 3%;
    span{
        color: #C10133;
    }
    @media (max-width: 600px) {
        font-size: 39px;
    }
`