import React from "react";
import { Button, Grid, Link } from "@mui/material";
import Logo from "../../assets/logoo.png";
import Icon from "../../assets/image 25.png";
import styled from "@emotion/styled";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Options from "../Header/options";

const ButtonEdit = styled(Button)(({ theme }) => ({
  padding: 10,
  paddingLeft: 50,
  paddingRight: 50,
}));

export default function Footer() {
  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignItems="center"
      textAlign={"center"}
      style={{
        background: '#fff',
        paddingTop: "3%",
        paddingBottom: "1%",
        color: '#0069C0'
      }}
    >
      <Grid item>
        <img style={{ width: 264, height: 138 }} src={Logo} alt="logo" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ textAlign: 'center', marginTop: 50 }}>Entregamos na sua transportadora</h3>
        <img style={{ width: 35, height: 35, marginTop: 50, marginLeft: 5 }} src={Icon} alt="logo" />
        </div>
        <h3>(Sem custos Adicionais)</h3>
      </Grid>
      <Grid item s>
        <h2 style={{ fontWeight: 800, marginTop: "3%", color: '#0069C0',marginBottom: 5 }}>
          Sobre nós.
        </h2>
        <p style={{ textAlign: 'left', marginTop: 8 }} >Telefone: (11) 2242-7969</p>
        <p style={{ textAlign: 'left', marginTop: 8 }}>Whatsapp: (11) 95268-2339</p>
        <p style={{ textAlign: 'left', marginTop: 8 }}>Email:  vendas@impactodistribuicao.com.br</p>
        <p style={{ textAlign: 'left', marginTop: 8, maxWidth: 430 }}>Endereço: Av. Edu Chaves, 703 - Parque Edu Chaves, São Paulo - SP, 02229-000</p>
      </Grid>
      <Grid item style={{ textAlign: 'left' }}>
      <h2 style={{ fontWeight: 800, marginTop: "3%", color: '#0069C0',marginBottom: 5 }}>
          Navegue pelo site.
        </h2>
        <Options topScroll={1}>Home</Options>
        <Options topScroll={500}>Sobre nós</Options>
        <Options topScroll={1600}>Catálogo</Options>
        <Options topScroll={2401}>Produtos</Options>
        <Options topScroll={2551}>Contato</Options>
      </Grid>
      <Grid item xs={12}>
        <p style={{ fontWeight: 400, marginTop: "3%", padding: '1%' }}>
          © 2024 Impacto. Todos os direitos reservados
        </p>
      </Grid>
    </Grid >
  );
}
