import React, { useEffect, useState } from "react";
import About from "../../Components/About";
import Banner from "../../Components/Banner";
import Cards from "../../Components/Cards";
import Contact from "../../Components/Contact";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Quality from "../../Components/Quality";
import Catalog from "../../Components/Catalog";
import Products from "../../Components/Products";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Banner1 from "../../Components/Banner1";
import Banner2 from "../../Components/Banner2";

export default function Home() {
  const [blackHeader, setBlackHeader] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [tamanho, setTamanho] = useState(1000);

  useEffect(() => {
    const scrollListener = () => {
      setScroll(window.scrollY)
      setTamanho(window.screen.width)
      if (window.scrollY > 10) {
        setBlackHeader(true);
      } else {
        setBlackHeader(false);
      }
    }

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  })
  return (
    <>
      <Header scroll={scroll} blackHeader={blackHeader} tamanho={tamanho} />
      <Carousel autoPlay infiniteLoop>
        <div>
          <Banner />
        </div>
        <div>
          <Banner1 />
        </div>
        <div>
          <Banner2 />
        </div>
      </Carousel>
      <About />
      <Cards />
      <Catalog />
      <Quality />
      <Products />
      {/* <Services />
      <CarroselComponent /> */}
      <Contact />
      <Footer />
    </>
  );
}
