import React from "react";
import Line from "../Line";
import Rolamento from '../../assets/Group40.png'
import { Container, ContainerNew, Text, Title } from './style.js'
import { Grid } from "@mui/material";

export default function About() {
  return (
    <ContainerNew>

    <Grid container justifyContent="space-between">
      <Grid item md={6}>

      <Container>

        <Line text={"Conheça nossa historía"} />

        <Title>
          Sobre nossa trajetória.
        </Title>
        <Text>
        A Impacto Distribuição é uma empresa especializada no fornecimento de rolamentos nacionais, importados e peças industriais para manutenção, agrícola e mecânica, trabalhamos também com a linha completa de  iluminação em led industrial, publica, solar e residencial e  oferecemos produtos de excelente qualidade com agilidade e eficiência. Nossa Visão é alcançar o reconhecimento pela excelência de sua atuação no mercado, por meios de confiabilidade e na qualidade do fornecimento.
        </Text>
        <Title>
          Áreas de atuação.
        </Title>
        <Grid container style={{ padding: '6% 0% 0% 8%' }}>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Têxtil
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Cerâmico
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Siderúrgico
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Mineração
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Alimentício
            </div>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Sucroalcooleiro
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Papel e Celulose
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ border: '1px solid #0069C0', padding: '2px', borderRadius: 5, textAlign: 'center', margin: '5%' }}>
            Indústria química
            </div>
          </Grid>
        </Grid>
        
      </Container>
      </Grid>
      <Grid item  md={6} style={{ marginTop: "5%" }}>
        <img src={Rolamento} alt="" />
      </Grid>
    </Grid>
    </ContainerNew>
  );
}
