import React from "react";
import Line from "../Line";
import Rolamento from '../../assets/Group40.png'
import { Container, ContainerNew, Text, Title } from './style.js'
import { Card, Grid } from "@mui/material";

import IconSecurity from '../../assets/shield-tick.png'
import IconAvaliation from '../../assets/box.png'
import IconRadar from '../../assets/diamond-2--diamond-money-payment-finance-wealth-jewelry (2).png'

export default function Quality() {
  return (
    <ContainerNew>
      <Container>
        <Title>
          Politica de qualidade.
        </Title>
        <Grid container justifyContent="space-evenly" >
        <Grid item xs={12} md={2.4} style={{ padding: '2%', textAlign: 'center' }}></Grid>
          <Grid item xs={12} md={2.4} style={{ padding: '2%', textAlign: 'center' }}>
          <Card raised style={{ minHeight: 356 }}>
              <img src={IconSecurity} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>Missão</h3>
              <Text>
              Fornecer soluções em rolamentos com excelência e confiabilidade, impulsionando o sucesso dos nossos clientes e parceiros.
              </Text>
            </Card>
          </Grid>
          <Grid item xs={12} md={2.4} style={{ padding: '2%', textAlign: 'center' }}>
          <Card raised style={{ minHeight: 356 }}>
              <img src={IconAvaliation} style={{ marginTop: 25, }} />
              <h3 style={{ marginTop: 45, }}>Visão</h3>
              <Text>
              Ser a referência em distribuição de rolamentos na região, reconhecida pela qualidade, inovação e compromisso com a sustentabilidade.
              </Text>
            </Card>
          </Grid>
          <Grid item xs={12} md={2.4} style={{ padding: '2%', textAlign: 'center' }}>
          <Card raised style={{ minHeight: 356 }}>
              <img src={IconRadar} style={{ marginTop: 25 }} />
              <h3 style={{ marginTop: 45, }}>Valores</h3>
              <Text>
              Ética e Transparência: Agir com honestidade, responsabilidade e clareza em todas as nossas relações.
              </Text>
            </Card>
          </Grid>
        <Grid item xs={12} md={2.4} style={{ padding: '2%', textAlign: 'center' }}></Grid>

        </Grid>
      </Container>
      
    </ContainerNew>
  );
}
