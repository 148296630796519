import React from "react";
import Line from "../Line";
import Rolamento from '../../assets/Group40.png'
import { Container, ContainerNew, Text, Title, TitleModal } from './style.js'
import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton, Typography } from "@mui/material";

import Nobre from '../../assets/catalogo-nobre-mancais.jpg'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconSecurity from '../../assets/catalogo-nobre-mancais.jpg'
import IconAvaliation from '../../assets/catalogos-sabo.jpg'
import IconRadar from '../../assets/catalogos-bgl.jpg'

import ImageSKF from '../../assets/catalogos-skf.jpg';
import ImageFRM from '../../assets/catalogos-frm.jpg';
import ImageTim from '../../assets/catalogos-timken.jpg'
import { useNavigate } from "react-router-dom";

import P13 from '../../assets/p13.jpg';
import P18 from '../../assets/p18.jpg';

export default function Products() {
  const [open, setOpen] = React.useState(false);
  const navigate  = useNavigate();
  const handleClickOpen = () => {
    navigate('/products')
    
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container style={{ marginBottom: '5%', marginTop: '5%' }}>
      <Grid item md={6}>
        <Container>
          <Line text={"Conheça nossos produtos"} />
          <Title>
            Produtos.
          </Title>
          <Text>
          Quer encontrar os produtos ideais para suas necessidades? Clique no botão abaixo! Com uma variedade de itens cuidadosamente selecionados, 
          estamos aqui para tornar sua busca por produtos simples e precisa. Acesse agora e comece a explorar. Sua próxima descoberta está apenas a alguns cliques de distância!
          </Text>
          
          <Button onClick={handleClickOpen} style={{ margin: '0% 0% 0% 8%', color: "#fff", background: '#0069C0', fontWeight: 'bold' }} variant="contained">Ver todos Produtos</Button>
        </Container>
      </Grid>
      
      <Grid item md={6}>
      <Grid container>

    <Card raised style={{ padding: '2%', margin: '2%', marginTop: '5%', paddingTop: 0, paddingBottom: '2%' }} >
      <img src={P18} style={{ marginTop: 25, }} />
      <h3 style={{ marginTop: 45, }}>Buchas</h3>
      <p style={{ marginTop: '15%' }}>BUCHA DE FIXAÇÃO | H, HE, HA, HS</p>
    </Card>
  
    <Card raised style={{ padding: '2%', margin: '2%', marginTop: '5%', paddingTop: 0, paddingBottom: '2%' }}>
      <img src={P13} style={{ marginTop: 25, }} />
      <h3 style={{ marginTop: 45, }}>Mancais linha SN</h3>
      <p style={{ marginTop: '10%' }}>Fabricados em ferro fundido cinzento,<br /> com vedação FS.</p>
    </Card>
 

</Grid>
</Grid>
    </Grid>
  );
}
