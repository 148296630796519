import styled from 'styled-components'

export const Container = styled.div`
    display:flex;
    margin: 0;
    margin-top: 150px;
    color: #FFF;
    @media (max-width: 600px) {
        display: block;
    }
`

export const Card = styled.div`
    text-align: center;
    padding: 5rem 5rem 5rem 5rem;
    background:  ${props => props.background};
    width: 33%;
    @media (max-width: 600px) {
        padding: 50px 0;
        width: 100%;
    }
`

export const CardNew = styled.div`
    min-height: 120px;
    background:  ${props => props.background};
    max-width: 100%;
    margin: 0;
    color: #fff;
    padding: 2%;
    @media (max-width: 600px) {
        img{
            display:none;
        }
    }
`

export const Title = styled.h1`
    font-weight: 800; 
    margin-top: 3%;
    span{
        color: #C10133;
    }
`

export const Description = styled.h3`
    font-weight: 800; 
    margin-top: 3%;
`